import React, { useState, useEffect, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import nexo from '../../nexoClient';
import { Page, Layout, DataList } from '@nimbus-ds/patterns';
import {
  Box,
  Button,
  Text,
  Card,
  Link,
  Thumbnail,
  useToast,
} from '@nimbus-ds/components';
import { WhatsappIcon, MailIcon } from '@nimbus-ds/icons';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import { addShipping, getOrder } from '../../services/tiendanube';
import { Order as OrderType, ProductsEntity } from '../../services/Order.types';
import { cotizarEnvio, addOrden, prepareOrden } from '../../services/andreani';
import { BultoType } from '../../services/CotizarEnvio.types';
import { DataType as CotizarEnvioDataType } from '../../services/CotizarEnvio.types';
import config from '../../config';
import useOrderFormValidation from '../../hooks/useOrderFormValidation';
import { makeId } from '../../utils/orderUtils';
import {
  AddOrdenRequestType,
  AddOrdenResponseType,
  OrdenType,
} from '../../services/AddOrden.types';
import EnvioForm from '../../components/EnvioForm';
import { ButtonWithSpinner, ListEnvios } from '../../components';

const getOrderData = async (orderID: number) => {
  const response = await getOrder(orderID);

  if (response === null) {
    return null;
  }

  return response.data;
};

const OrderPage: React.FC = () => {
  const { orderId }: { orderId: string | undefined } = useParams();

  const [order, setOrder] = useState<OrderType | null>(null);
  const [formData, setFormData] = useState<OrdenType>({
    contrato: '',
    sucursal: null,
    idPedido: makeId(null),
    seguro: '',
    volumen: 0,
    kilos: 0,
    pesoAforado: 0,
    valorDeclarado: 0,
    largoCm: -1,
    anchoCm: -1,
    altoCm: -1,
  });
  const { invalidFields, isInvalidForm } = useOrderFormValidation(formData);
  const [resetIndex, setResetIndex] = useState<number>(0);
  const [cotizacion, setCotizacion] = useState<CotizarEnvioDataType | null>(
    null,
  );
  const [cotizando, setCotizando] = useState<boolean>(false);
  const [creando, setCreando] = useState<boolean>(false);

  const { addToast } = useToast();

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/orders', text: 'Volver a los pedidos' });

    if (!orderId) {
      return;
    }

    getOrderData(parseInt(orderId)).then((data) => {
      if (!data) {
        addToast({
          id: 'toast-error',
          type: 'danger',
          text: 'No se pudo cargar el pedido',
          duration: 8000,
        });
        return;
      }
      setOrder(data);
    });
  }, []);

  useEffect(() => {
    if (order === null) {
      return;
    }

    setFormData({
      ...formData,
      anchoCm: parseFloat(order.products[0].width),
      altoCm: parseFloat(order.products[0].height),
      largoCm: parseFloat(order.products[0].depth),
    });
  }, [order]);

  useEffect(() => {
    setCotizacion(null);
  }, [resetIndex]);

  const handleCotizar = async () => {
    if (order === null) {
      return;
    }

    setCotizando(true);

    const bulto: BultoType = {
      volumen: formData.volumen,
      kilos: formData.kilos,
      pesoAforado: formData.pesoAforado,
      valorDeclarado: formData.valorDeclarado,
    };

    const response = await cotizarEnvio({
      cpDestino: parseInt(order.shipping_address.zipcode),
      cliente: config.ANDREANI_CLIENT,
      contrato: formData.contrato,
      bultos: [bulto],
    });

    const newCotizacion = response && response.data ? response.data : null;

    setCotizacion(newCotizacion);
    setCotizando(false);

    addToast({
      id: 'toast-' + resetIndex,
      type: 'primary',
      text: 'Cotización realizada exitosamente!',
      duration: 4000,
    });
  };

  const handleCrear = async () => {
    if (creando || order === null || cotizacion === null) {
      return;
    }

    const conIvaMasSeguro = cotizacion ? cotizacion.tarifaConIva.total : 0;

    if (
      parseFloat(order.shipping_cost_owner) > 0 &&
      conIvaMasSeguro > parseFloat(order.shipping_cost_owner) &&
      confirm(
        'El costo de envío es mayor al que se le cobró al cliente. ¿Desea continuar?',
      ) === false
    ) {
      return;
    }

    const orden: AddOrdenRequestType | boolean = prepareOrden(
      order,
      formData,
      cotizacion,
    );

    if (orden === false) {
      return;
    }

    setCreando(true);

    const response: AddOrdenResponseType = await addShipping(order.id, {
      ...orden,
      costo_envio: cotizacion.tarifaConIva.total,
    });

    setCreando(false);

    if (response.success) {
      addToast({
        id: 'toast-' + resetIndex,
        type: 'success',
        text: 'Envio creado exitosamente!',
        duration: 8000,
      });
      setResetIndex((i) => i + 1);
    }
  };

  if (!order) {
    return null;
  }

  return (
    <>
      <Page maxWidth="1200px" backgroundColor={'neutral-surface'}>
        <Page.Header
          buttonStack={
            <Link as="a" href={`/orders`} appearance="neutral">
              <Text>Volver</Text>
            </Link>
          }
          title={`#${order.number}`}
        ></Page.Header>
        <Page.Body px={{ xs: 'none', md: '6' }}>
          <Layout columns="1">
            <Layout.Section>
              <Layout columns="2 - asymmetric">
                <Layout.Section>
                  <Card padding="none">
                    <Card.Header title="Detalle de la orden" padding="base" />
                    <DataList bottomDivider={false}>
                      {order.products && (
                        <DataList.Row
                          paddingTop="none"
                          paddingBottom={'2-5'}
                          topDivider={false}
                        >
                          {order.products.map((product: ProductsEntity) => (
                            <Box
                              key={`product-${product.id}`}
                              display="flex"
                              justifyContent="space-between"
                              paddingY="2-5"
                            >
                              <Box
                                display="flex"
                                flexDirection="row"
                                gridGap={'2'}
                              >
                                <Thumbnail
                                  src={product.image.src}
                                  alt={product.name}
                                  width="72px"
                                />
                                <Box
                                  display="flex"
                                  flexDirection={'column'}
                                  gap="1"
                                >
                                  <Text
                                    color={'primary-interactive'}
                                    fontWeight={'bold'}
                                  >
                                    {product.name}
                                  </Text>
                                  {product.variant_values && (
                                    <Text>{product.variant_values[0]}</Text>
                                  )}
                                  <Text fontSize={'caption'}>
                                    SKU: {product.sku}
                                  </Text>
                                  <Text>
                                    {product.quantity}x $
                                    {new Intl.NumberFormat().format(
                                      product.price,
                                    )}
                                  </Text>
                                </Box>
                              </Box>
                              <Text>
                                ${new Intl.NumberFormat().format(product.price)}
                              </Text>
                            </Box>
                          ))}
                        </DataList.Row>
                      )}
                      <DataList.Row
                        paddingY={'2-5'}
                        topDivider={false}
                        backgroundColor={'neutral-surface'}
                      >
                        <Box display="flex" justifyContent="space-between">
                          <Text>Subtotal</Text>
                          <Text>
                            ${new Intl.NumberFormat().format(order.subtotal)}
                          </Text>
                        </Box>
                      </DataList.Row>
                      <DataList.Row
                        paddingY={'2-5'}
                        topDivider={false}
                        backgroundColor={'neutral-surface'}
                      >
                        <Box display="flex" justifyContent="space-between">
                          <Text>Envío ({order.shipping_option})</Text>
                          <Text>${order.shipping_cost_owner}</Text>
                        </Box>
                      </DataList.Row>
                      <DataList.Row
                        paddingY={'2-5'}
                        topDivider={false}
                        backgroundColor={'neutral-surface'}
                      >
                        <Box display="flex" justifyContent="space-between">
                          <Text fontWeight={'bold'}>Total</Text>
                          <Text fontWeight={'bold'}>
                            ${new Intl.NumberFormat().format(order.total)}
                          </Text>
                        </Box>
                      </DataList.Row>
                    </DataList>
                  </Card>
                </Layout.Section>
                <Layout.Section>
                  <Card padding="none">
                    <Card.Header title="Datos del cliente" padding="base" />
                    <Card.Body>
                      <DataList>
                        <DataList.Row paddingY={'2-5'} topDivider={false}>
                          <Text>{order.contact_name}</Text>
                          <Box display="flex" flexDirection="row" gap="1">
                            <MailIcon size={16} />
                            <Text>{order.contact_email}</Text>
                          </Box>
                          <Box display="flex" flexDirection="row" gap="1">
                            <WhatsappIcon size={16} />
                            <Text>{order.contact_phone}</Text>
                          </Box>
                          <Box display="flex" flexDirection="column" gap="none">
                            <Text>DNI/CUIL</Text>
                            <Text>{order.contact_identification}</Text>
                          </Box>
                        </DataList.Row>
                      </DataList>
                    </Card.Body>
                  </Card>
                  <Card padding="none">
                    <Card.Header title="Dirección de envío" padding="base" />
                    <Card.Body>
                      <DataList>
                        <DataList.Row paddingY={'2-5'} topDivider={false}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            gap="none"
                            marginBottom="3"
                          >
                            <Text>{order.shipping_address.name}</Text>
                            <Text>{order.shipping_address.phone}</Text>
                          </Box>
                          <Box display="flex" flexDirection="column" gap="none">
                            <Text>Dirección:</Text>
                            <Text>{`${order.shipping_address.address} ${order.shipping_address.number} (${order.shipping_address.zipcode})`}</Text>
                            <Text>{`${order.shipping_address.city}, ${order.shipping_address.province}`}</Text>
                          </Box>
                        </DataList.Row>
                      </DataList>
                    </Card.Body>
                  </Card>
                </Layout.Section>
              </Layout>

              <Suspense>
                {orderId && (
                  <ListEnvios
                    key={'listEnvios-' + resetIndex}
                    id_order={orderId}
                    title="Envíos Andreani"
                    shipping_tracking_number={
                      order.shipping_tracking_number
                        ? order.shipping_tracking_number
                        : ''
                    }
                  />
                )}
              </Suspense>

              <Card>
                <Card.Header title="Crear envío Andreani" />
                <Card.Body>
                  <Box display="flex" flexDirection="column" gap="4">
                    {order && (
                      <EnvioForm
                        key={'envioForm-' + resetIndex}
                        orderNumber={order.number}
                        orderTotal={order.total}
                        pickupType={order.shipping_pickup_type}
                        zipcode={parseInt(order.shipping_address.zipcode)}
                        shippingCost={parseFloat(order.shipping_cost_owner)}
                        shippingOptionReference={
                          order.shipping_option_reference
                        }
                        anchoCm={0}
                        altoCm={0}
                        largoCm={0}
                        cotizando={cotizando}
                        cotizacion={cotizacion}
                        onChangeFormData={(newFormData) => {
                          setFormData(newFormData);
                        }}
                      />
                    )}
                  </Box>
                </Card.Body>
              </Card>
              <Box display="flex" justifyContent="flex-end" gap="2">
                <ButtonWithSpinner
                  appearance="neutral"
                  onClick={handleCotizar}
                  disabled={isInvalidForm || creando}
                  loading={cotizando}
                >
                  {cotizando ? 'Cotizando' : 'Cotizar'}
                </ButtonWithSpinner>
                <ButtonWithSpinner
                  appearance="primary"
                  visible={cotizacion !== null}
                  onClick={handleCrear}
                  loading={creando}
                  disabled={isInvalidForm || cotizando || creando}
                >
                  {creando ? 'Creando' : 'Crear'}
                </ButtonWithSpinner>
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
};

export default OrderPage;

import { ReactNode } from 'react';
import { Skeleton, Table } from '@nimbus-ds/components';
import { DataTable, DataList } from '@nimbus-ds/patterns';

interface ITableSkeletonProps {
  cols: number;
  rows: number;
  header?: ReactNode;
  footer?: ReactNode;
  checkbox?: any;
}

const Cells: React.FC<{ cols: number; indexRow: number }> = ({
  cols,
  indexRow,
}: {
  cols: number;
  indexRow: number;
}) => {
  const arrayCols = new Array(cols);

  return (
    <>
      {Array.from(arrayCols, (item, index) => (
        <Table.Cell key={`${indexRow}-${index}`}>
          <Skeleton height="1rem" width="4rem" borderRadius="2" />
        </Table.Cell>
      ))}
    </>
  );
};

const TableSkeleton: React.FC<ITableSkeletonProps> = ({
  cols,
  rows,
  header,
  footer,
  checkbox,
}: ITableSkeletonProps) => {
  const countRows = new Array(rows);

  return (
    <DataTable header={header} footer={footer}>
      {Array.from(countRows, (order: number, index) => (
        <DataTable.Row
          key={index}
          backgroundColor={{
            rest: 'neutral-background',
            hover: 'neutral-surface',
          }}
          checkbox={checkbox}
        >
          <Cells cols={cols} indexRow={index} />
        </DataTable.Row>
      ))}
    </DataTable>
  );
};

export default TableSkeleton;

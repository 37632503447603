import { IconProps, Tag, TagProperties, Text } from '@nimbus-ds/components';
import { CashIcon } from '@nimbus-ds/icons';

interface TagStatusProps {
  appearance: TagProperties['appearance'];
  title: string;
  subtitle: string;
  icon: React.ReactNode;
}

const TagStatus: React.FC<TagStatusProps> = ({
  appearance,
  title,
  subtitle,
  icon,
}: TagStatusProps) => {
  return (
    <>
      <Tag appearance={appearance}>
        {icon ? icon : null}
        <Text
          color="primary-textHigh"
          fontSize="caption"
          lineClamp={1}
          title={title}
        >
          {title}
        </Text>
      </Tag>
      {subtitle && <Text fontSize="caption">{subtitle}</Text>}
    </>
  );
};

export default TagStatus;

import { FormField } from '@nimbus-ds/patterns';
import { Box, Text, Input, Label } from '@nimbus-ds/components';
import { DataType as CotizarEnvioDataType } from '../../services/CotizarEnvio.types';

interface ICotizacionWidgetProps {
  cotizacion: CotizarEnvioDataType | null;
  valorDeclarado: number;
  shippingCost: number;
  decimals?: number;
  skeleton?: boolean;
}

const DOS_PORCIENTO = 0.02;

const CotizacionWidget: React.FC<ICotizacionWidgetProps> = ({
  cotizacion,
  valorDeclarado,
  shippingCost,
  skeleton,
}: ICotizacionWidgetProps) => {
  const conIvaMasSeguro = cotizacion ? cotizacion.tarifaConIva.total : 0;
  const sinIvaMasSeguro = cotizacion ? cotizacion.tarifaSinIva.total : 0;
  const seguro = cotizacion ? valorDeclarado * DOS_PORCIENTO : 0;
  const sinIvaSinSeguro = cotizacion
    ? cotizacion.tarifaSinIva.total - seguro
    : 0;

  const skeletonContent = (
    <>
      <Box gap="2" display="grid">
        <Label>Con IVA + Seguro</Label>
        <Input.Skeleton />
      </Box>
      <Box gap="2" display="grid">
        <Label>Sin IVA + Seguro</Label>
        <Input.Skeleton />
      </Box>
      <Box gap="2" display="grid">
        <Label>Sin IVA - Seguro</Label>
        <Input.Skeleton />
      </Box>
      <Box gap="2" display="grid">
        <Label>Seguro (2%)</Label>
        <Input.Skeleton />
      </Box>
    </>
  );

  const appearance = conIvaMasSeguro < shippingCost ? 'success' : 'danger';

  const inputs = (
    <>
      <FormField.Input
        label="Con IVA + Seguro"
        placeholder="0.00"
        append={<Text color="neutral-textDisabled">$</Text>}
        appendPosition="start"
        disabled={true}
        value={conIvaMasSeguro}
        appearance={appearance}
      />
      <FormField.Input
        label="Sin IVA + Seguro"
        placeholder="0.00"
        append={<Text color="neutral-textDisabled">$</Text>}
        appendPosition="start"
        disabled={true}
        value={sinIvaMasSeguro}
      />
      <FormField.Input
        label="Sin IVA - Seguro"
        placeholder="0.00"
        append={<Text color="neutral-textDisabled">$</Text>}
        appendPosition="start"
        disabled={true}
        value={sinIvaSinSeguro}
      />
      <FormField.Input
        label="Seguro (2%)"
        placeholder="0.00"
        append={<Text color="neutral-textDisabled">$</Text>}
        appendPosition="start"
        disabled={true}
        value={seguro}
      />
    </>
  );

  const content = skeleton ? skeletonContent : inputs;

  return (
    <>
      <Text>Cotización</Text>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: '1fr 1fr',
          md: '1fr 1fr 1fr 1fr',
        }}
        gap="4"
      >
        {content}
      </Box>
    </>
  );
};

export default CotizacionWidget;

import { useEffect, useState } from 'react';
import tokens from '@nimbus-ds/tokens/dist/js/tokens';
import useWindowWidth from '../../hooks/useWindowWidth';

interface ResponsiveComponentProps {
  mobileContent: React.ReactNode;
  desktopContent: React.ReactNode;

  breakpoint?: typeof tokens.breakpoint.md;
}

const ResponsiveComponent: React.FC<ResponsiveComponentProps> = ({
  mobileContent,
  desktopContent,
  breakpoint = tokens.breakpoint.md,
}: ResponsiveComponentProps) => {
  const windowWidth = useWindowWidth();
  const [isMounted, setIsMounted] = useState(false);

  const breakpointValue = breakpoint.value.replace('px', '');

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  if (windowWidth !== null && windowWidth <= breakpointValue) {
    return <>{mobileContent}</>;
  } else {
    return <>{desktopContent}</>;
  }
};

export default ResponsiveComponent;

import nexo from '../nexoClient';
import { getStoreInfo, getSessionToken } from '@tiendanube/nexo/helpers';
import { OrdersResponseType } from './Orders.types';
import { OrderResponseType } from './Order.types';
import http_build_query from './tools';
import { OrderEnviosResponseType } from '../types/envios';
import config from '../config';

const cache: { [key: string]: any } = {};

const storeId = (name: string) => {
  return 'store-id-' + name;
};

const getStoreInfoCache = async () => {
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === 'development') {
    return {
      id: 2713424,
    };
  }

  const cacheId = storeId('storeInfo');
  if (!cache[cacheId]) {
    const storeInfo = await getStoreInfo(nexo);
    cache[cacheId] = storeInfo;
  }
  return cache[cacheId];
};

const getSessionTokenCache = async () => {
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === 'development') {
    return '';
  }

  const cacheId = storeId('sessionToken');
  if (!cache[cacheId]) {
    const sessionToken = await getSessionToken(nexo);
    cache[cacheId] = sessionToken;
  }
  return cache[cacheId];
};

const getBaseUri = async () => {
  const storeInfo = await getStoreInfoCache();
  return `${config.API_URL}/${storeInfo.id}`;
};

export const makeRequest = async (
  method: string,
  endpoint: string,
  params = {},
) => {
  const uri = await getBaseUri();
  const bearerToken = await getSessionTokenCache();
  let fullUri = uri + endpoint;
  let body = null;

  if (method == 'POST') {
    body = JSON.stringify(params);
  } else {
    fullUri += '?' + http_build_query(params);
  }

  try {
    const response = await fetch(fullUri, {
      method,
      headers: {
        'Content-Type': 'application/json',
        UserAgent: 'SA Andreani (soporte@alejosotelo.com.ar)',
        Authentication: `Bearer ${bearerToken}`,
      },
      body,
    });

    const data = await response.json();
    return data;
  } catch (e) {
    console.log(e);
  }

  return null;
};

export const getOrders = async (params = {}): Promise<OrdersResponseType> => {
  return makeRequest('GET', '/orders', params);
};

export const getOrder = async (
  id: number,
  params = {},
): Promise<OrderResponseType> => {
  return makeRequest('GET', `/orders/${id}`, params);
};

export const addShipping = async (
  id: number,
  params = {},
): Promise<ShippingResponseType> => {
  return makeRequest('POST', `/orders/${id}/shipping`, params);
};

export const getEnviosByOrderId = async (
  id: string,
  params = {},
): Promise<OrderEnviosResponseType> => {
  return makeRequest('GET', `/orders/${id}/envios`, params);
};

export const fulfill = async (
  id: string,
  params: {
    shipping_tracking_number: string;
    shipping_tracking_url?: string;
    notify_customer?: boolean;
  },
): Promise<FulfillResponseType> => {
  return makeRequest('POST', `/orders/${id}/fulfill`, params);
};

export const getFulfillmentsOrders = async (
  id: string,
  params = {},
): Promise<unknown> => {
  return makeRequest('GET', `/orders/${id}/fulfillment-orders`, params);
};

type ShippingResponseType = {
  success: boolean;
  data: ShippingResponseDataType;
  errors: string;
};

type ShippingResponseDataType = {
  estado: string;
  tipo: string;
  sucursalDeDistribucion: {
    nomenclatura?: string;
    descripcion?: string;
    id?: string;
  };
  sucursalDeRendicion: {
    nomenclatura?: string;
    descripcion?: string;
    id?: string;
  };
  sucursalDeImposicion: {
    nomenclatura?: string;
    descripcion?: string;
    id?: string;
  };
  sucursalAbastecedora: {
    nomenclatura?: string;
    descripcion?: string;
    id?: string;
  };
  fechaCreacion: string;
  numeroDePermisionaria: string;
  descripcionServicio: string;
  bultos: Array<{
    numeroDeBulto: string;
    numeroDeEnvio: string;
    totalizador: string;
    linking: Array<{
      meta: string;
      contenido: string;
    }>;
  }>;
  agrupadorDeBultos: string;
  etiquetasPorAgrupador: string;
};

export type FulfillResponseType = {
  success: boolean;
  data: FulfillResponseDataType;
  errors: string;
};

export type FulfillResponseDataType = {
  id: number;
  token: string;
  store_id: number;
  contact_email: string;
  contact_name: string;
  contact_phone: string;
  contact_identification: null;
  shipping_min_days: number;
  shipping_max_days: number;
  billing_name: string;
  billing_phone: string;
  billing_address: string;
  billing_number: string;
  billing_floor: string;
  billing_locality: string;
  billing_zipcode: string;
  billing_city: string;
  billing_province: string;
  billing_country: string;
  shipping_cost_owner: string;
  shipping_cost_customer: string;
  coupon: unknown[];
  promotional_discount: PromotionalDiscount;
  subtotal: string;
  discount: string;
  discount_coupon: string;
  discount_gateway: string;
  total: string;
  total_usd: string;
  checkout_enabled: boolean;
  weight: string;
  currency: string;
  language: string;
  gateway: string;
  gateway_id: null;
  gateway_name: string;
  shipping: string;
  shipping_option: string;
  shipping_option_code: null;
  shipping_option_reference: null;
  shipping_pickup_details: null;
  shipping_tracking_number: string;
  shipping_tracking_url: string;
  shipping_store_branch_name: null;
  shipping_pickup_type: string;
  shipping_suboption: unknown[];
  extra: unknown;
  storefront: string;
  note: null;
  created_at: string;
  updated_at: string;
  completed_at: CompletedAt;
  next_action: string;
  payment_details: PaymentDetails;
  attributes: unknown[];
  customer: Customer;
  products: Product[];
  number: number;
  cancel_reason: null;
  owner_note: string;
  cancelled_at: null;
  closed_at: null;
  read_at: null;
  status: string;
  payment_status: string;
  gateway_link: null;
  shipping_carrier_name: null;
  shipping_address: Address;
  shipping_status: string;
  shipped_at: null;
  paid_at: string;
  landing_url: null;
  client_details: ClientDetails;
  app_id: number;
};

export type ClientDetails = {
  browser_ip: null;
  user_agent: null;
};

export type CompletedAt = {
  date: Date;
  timezone_type: number;
  timezone: string;
};

export type Customer = {
  id: number;
  name: string;
  email: string;
  identification: null;
  phone: string;
  note: null;
  default_address: Address;
  addresses: Address[];
  billing_name: string;
  billing_phone: string;
  billing_address: string;
  billing_number: string;
  billing_floor: string;
  billing_locality: string;
  billing_zipcode: string;
  billing_city: string;
  billing_province: string;
  billing_country: string;
  extra: unknown;
  total_spent: string;
  total_spent_currency: string;
  last_order_id: number;
  active: boolean;
  first_interaction: string;
  created_at: string;
  updated_at: string;
};

export type Address = {
  address: null | string;
  city: null | string;
  country: null | string;
  created_at: string;
  default: boolean;
  floor: null | string;
  id: number;
  locality: null | string;
  name: string;
  number: null | string;
  phone: string;
  province: null | string;
  updated_at: string;
  zipcode: null | string;
  customs?: null;
};

export type PaymentDetails = {
  method: null;
  credit_card_company: null;
  installments: number;
};

export type Product = {
  id: number;
  depth: string;
  height: string;
  name: string;
  price: string;
  compare_at_price: string;
  product_id: number;
  image: Image;
  quantity: number;
  free_shipping: boolean;
  weight: string;
  width: string;
  variant_id: number;
  variant_values: any[];
  properties: any[];
  sku: null;
  barcode: null;
};

export type Image = {
  id: number;
  product_id: number;
  src: string;
  position: number;
  alt: any[];
  created_at: string;
  updated_at: string;
};

export type PromotionalDiscount = {
  id: null;
  store_id: number;
  order_id: number;
  created_at: string;
  total_discount_amount: string;
  contents: any[];
  promotions_applied: any[];
};

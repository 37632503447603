export type ConsultarSucursalesRequestType = {
  codigoPostal?: number;
  canal?: 'B2C' | 'B2B';
  seHaceAtencionAlCliente?: boolean;
};

export type ConsultarSucursalesResponseType = {
  data: DataType;
};

export type DataType = Array<SucursalType>;

export type SucursalType = {
  id: number;
  codigo: string;
  numero: string;
  descripcion: string;
  canal: Canal;
  direccion: Direccion;
  coordenadas: Coordenadas;
  horarioDeAtencion: HorarioDeAtencion;
  datosAdicionales: DatosAdicionales;
  telefonos: string[];
  codigosPostalesAtendidos: string[];
};

export enum Canal {
  B2C = 'B2C',
}

export type Coordenadas = {
  latitud: string;
  longitud: string;
};

export type DatosAdicionales = {
  seHaceAtencionAlCliente: boolean;
  tipo: Tipo;
  admiteEnvios: boolean;
  entregaEnvios: boolean;
  conBuzonInteligente: boolean;
};

export enum Tipo {
  CentroDeDistribucion = 'CENTRO DE DISTRIBUCION',
  Planta = 'PLANTA',
  Sucursal = 'SUCURSAL',
  Dealer = 'DEALER',
}

export type Direccion = {
  calle: string;
  numero: string;
  provincia: string;
  localidad: string;
  region: string;
  pais: Pais;
  codigoPostal: string;
};

export enum Pais {
  Argentina = 'Argentina',
}

export enum HorarioDeAtencion {
  LunesAViernesDe0800A1800SábadosDe0800A1200Hs = 'Lunes a viernes de 08:00 a 18:00 – Sábados de 08:00 a 12:00Hs',
  LunesAViernesDe0800A1800SábadosDe0800A1300 = 'Lunes a Viernes de 08:00 a 18:00 - Sábados de 08:00 a 13:00',
  LunesAViernesDe0800A1800SábadosDe0800A1300Hs = 'Lunes a viernes de 08:00 a 18:00 – Sábados de 08:00 a 13:00Hs',
  LunesAViernesDe0900A1800Sábados0800A1300Hs = 'Lunes a Viernes de 09:00 a 18:00 - Sábados 08:00 a 13:00Hs',
}

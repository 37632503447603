import { Button, ButtonProps, Icon, ToastProps } from '@nimbus-ds/components';
import { getEtiqueta } from '../../services/andreani';
import { useState } from 'react';
import { PrinterIcon } from '@nimbus-ds/icons';
import { downloadFile } from '../../services/tools';

type ButtonPrintEnvioProps = {
  numeroAndreani: string;
  toast: (toast: Omit<ToastProps, 'autoClose'>) => void;
  loading: React.ReactNode;
  children?: React.ReactNode;
} & ButtonProps;

const ButtonPrintEnvio: React.FC<ButtonPrintEnvioProps> = (
  props: ButtonPrintEnvioProps,
) => {
  const [imprimiendo, setImprimiendo] = useState<boolean>(false);

  const handleImprimir = async () => {
    props.toast({
      id: 'toast-info' + props.numeroAndreani,
      type: 'primary',
      text: 'Descargando etiqueta...',
      duration: 8000,
    });

    setImprimiendo(true);

    const response = await getEtiqueta(props.numeroAndreani);

    if (!(response instanceof Blob)) {
      // convert response.error to string
      const error = JSON.stringify(response.error);
      props.toast({
        id: 'toast-error-' + props.numeroAndreani,
        type: 'danger',
        text: `No se pudo obtener la etiqueta: ${error}`,
        duration: 8000,
      });
      setImprimiendo(false);
      return;
    }

    downloadFile(response, 'etiqueta.pdf', 'application/pdf');

    props.toast({
      id: 'toast-success' + props.numeroAndreani,
      type: 'success',
      text: 'Etiqueta descargada',
    });

    setImprimiendo(false);
  };

  const { numeroAndreani, toast, ...rest } = props;
  rest.appearance = rest.appearance ?? 'neutral';
  rest.disabled = rest.disabled ?? imprimiendo;

  return (
    <Button {...rest} onClick={handleImprimir}>
      {imprimiendo && rest.loading}
      {!imprimiendo && rest.children}
    </Button>
  );
};

export default ButtonPrintEnvio;

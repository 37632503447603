function urlencode(str) {
  str = (str + '').toString();
  return encodeURIComponent(str)
    .replace(/!/g, '%21')
    .replace(/'/g, '%27')
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')
    .replace(/\*/g, '%2A')
    .replace(/%20/g, '+')
    .replace(/%(5B|5D)/g, (str, hex) => String.fromCharCode(parseInt(hex, 16)));
}

function http_build_query(formdata, numeric_prefix, arg_separator) {
  var value,
    key,
    tmp = [];

  var _http_build_query_helper = function (key, val, arg_separator) {
    var k,
      tmp = [];
    if (val === true) {
      val = '1';
    } else if (val === false) {
      val = '0';
    }
    if (val !== null && typeof val === 'object') {
      for (k in val) {
        if (val[k] !== null) {
          tmp.push(
            _http_build_query_helper(
              key + '[' + k + ']',
              val[k],
              arg_separator,
            ),
          );
        }
      }
      return tmp.join(arg_separator);
    } else if (typeof val !== 'function') {
      return urlencode(key) + '=' + urlencode(val);
    } else if (typeof val == 'function') {
      return '';
    } else {
      throw new Error('There was an error processing for http_build_query().');
    }
  };

  if (!arg_separator) {
    arg_separator = '&';
  }
  for (key in formdata) {
    value = formdata[key];
    if (numeric_prefix && !isNaN(key)) {
      key = String(numeric_prefix) + key;
    }
    tmp.push(_http_build_query_helper(key, value, arg_separator));
  }

  return tmp.join(arg_separator);
}

export default http_build_query;

export function downloadFile(blob, filename, mime) {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  // const blob = new Blob([data], { type: mime || 'application/octet-stream' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE doesn't allow using a blob object directly as link href.
    // Workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
    return;
  }
  // Other browsers
  // Create a link pointing to the ObjectURL containing the blob
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', filename);
  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(blobURL);
  }, 100);
}

import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';

import '@nimbus-ds/styles/dist/index.css';
import '@nimbus-ds/styles/dist/themes/dark.css';

const container = document.getElementById('root');

if (!container) {
  throw new Error('No existe el elemento root');
}
const root = createRoot(container);
root.render(<App />);

import {
  HomeIcon,
  PictureIcon,
  TagIcon,
  OrderedListIcon,
} from '@nimbus-ds/icons';

export const appRoutes = [
  {
    title: 'Inicio',
    name: 'inicio',
    slug: '/',
    icon: HomeIcon,
  },
  {
    title: 'Pedidos',
    name: 'pedidos',
    slug: '/orders',
    icon: OrderedListIcon,
  },
  {
    title: 'Galería de ejemplos',
    name: 'galeria-de-ejemplos',
    slug: '/examples',
    icon: PictureIcon,
  },
  {
    title: 'Productos de mi tienda',
    name: 'productos-de-mi-tienda',
    slug: '/products',
    icon: TagIcon,
  },
];

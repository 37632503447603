import { useEffect, useState } from 'react';
import { Box, Input, Label } from '@nimbus-ds/components';
import { FormField, FormFieldSelectProps } from '@nimbus-ds/patterns';

interface FormFieldSelectSkeletonProps extends FormFieldSelectProps {
  skeleton?: boolean;
}

const FormFieldSelectSkeleton: React.FC<FormFieldSelectSkeletonProps> = (
  props: FormFieldSelectSkeletonProps,
) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  const skeletonContent = (
    <Box gap="2" display="grid">
      <Label>{props.label}</Label>
      <Input.Skeleton />
    </Box>
  );

  const { skeleton, ...rest } = props;

  const input = <FormField.Select {...rest} />;

  return skeleton ? skeletonContent : input;
};

export default FormFieldSelectSkeleton;

import { Order as OrderType } from '../services/Order.types';

const getCurrentMonthLetters = () => {
  const months = [
    'E',
    'F',
    'MZ',
    'AB',
    'MY',
    'JN',
    'JL',
    'AG',
    'SE',
    'O',
    'N',
    'D',
  ];

  const m = new Date().getMonth();

  return months[m];
};

const getCurrentDayNumber = () => {
  return new Date().getDate();
};

export const makeId = (orderNumber: number | null): string => {
  if (orderNumber === null) {
    return '';
  }

  const currentMonthLetters = getCurrentMonthLetters();
  const currentDayNumber = getCurrentDayNumber();
  const contador = 0;

  return `${currentMonthLetters}${currentDayNumber}-${contador}-${orderNumber}`;
};

export const calcValorDeclarado = (total: number) => {
  // (order.total -40%) / 2
  return (total * 0.6) / 2;
};

export const calcPesoAforado = (
  altoCm: number,
  anchoCm: number,
  largoCm: number,
) => {
  const constante = 0.35;
  const volumen = anchoCm * altoCm * largoCm; // m3
  const pesoAforado = (volumen * constante) / 1000; // gramos

  return pesoAforado;
};

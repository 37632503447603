import React from 'react';
import { InteractiveList } from '@nimbus-ds/patterns';
import { Skeleton, Box } from '@nimbus-ds/components';

const ListEnviosItemSkeleton: React.FC = () => (
  <InteractiveList.ButtonItemSkeleton title="" description="">
    <Box display="flex" gap="1" mt="2">
      <Skeleton height="1rem" width="4rem" borderRadius="2" />
      <Skeleton height="1rem" width="4rem" borderRadius="2" />
      <Skeleton height="1rem" width="4rem" borderRadius="2" />
    </Box>
  </InteractiveList.ButtonItemSkeleton>
);

export default ListEnviosItemSkeleton;

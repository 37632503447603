import { useEffect, useState } from 'react';
import { Box, Text, Select } from '@nimbus-ds/components';
import { DataType as CotizarEnvioDataType } from '../../services/CotizarEnvio.types';
import { DataType as ConsultarSucursalesDataType } from '../../services/ConsultarSucursales.types';
import { OrdenType } from '../../services/AddOrden.types';
import useOrderFormValidation from '../../hooks/useOrderFormValidation';
import { FormField } from '@nimbus-ds/patterns';
import {
  contratos,
  consultarSucursales,
  getPuntosDeTerceros,
  getContratoEntregaByPickupType,
  findContrato,
  getSucursalDescripcion,
} from '../../services/andreani';
import FormFieldSelectSkeleton from '../FormFieldSelectSkeleton';
import CotizacionWidget from '../CotizacionWidget';
import { calcPesoAforado, calcValorDeclarado, makeId } from '../../utils';
import { PickupType } from '../../services/Order.types';
import usePrevious from '../../hooks/usePrevious';

interface EnvioFormProps {
  orderNumber: number;
  orderTotal: number;
  pickupType: PickupType;
  shippingCost: number;
  shippingOptionReference: string;
  zipcode: number | null;
  largoCm: number;
  anchoCm: number;
  altoCm: number;
  cotizando: boolean;
  cotizacion: CotizarEnvioDataType | null;
  onChangeFormData: (formData: OrdenType) => void;
}

const getSucursalesData = async ({
  contrato,
  zipcode,
}: {
  contrato: string;
  zipcode: number;
}) => {
  const pickType = findContrato(contrato)?.type;

  const promiseFn =
    pickType === PickupType.pick ? getPuntosDeTerceros : consultarSucursales;

  const responseSucursales = await promiseFn({
    atencionPorCodigoPostal: zipcode,
    contrato: contrato,
    canal: 'B2C',
    seHaceAtencionAlCliente: true,
  });

  if (responseSucursales === null) {
    return [];
  }

  return responseSucursales.data;
};

const EnvioForm: React.FC<EnvioFormProps> = ({
  orderNumber,
  orderTotal,
  pickupType,
  shippingCost,
  shippingOptionReference,
  zipcode,
  largoCm,
  anchoCm,
  altoCm,
  cotizando,
  cotizacion,
  onChangeFormData,
}: EnvioFormProps) => {
  const [formData, setFormData] = useState<OrdenType>({
    contrato: '',
    sucursal: null,
    idPedido: makeId(null),
    seguro: '',
    volumen: 0,
    kilos: 0,
    pesoAforado: 0,
    valorDeclarado: 0,
    largoCm: largoCm,
    anchoCm: anchoCm,
    altoCm: altoCm,
  });
  const defaultIdSucursal =
    shippingOptionReference.indexOf('-id') >= 0
      ? parseInt(shippingOptionReference.replace('-id', ''))
      : 0;
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  const [sucursales, setSucursales] = useState<ConsultarSucursalesDataType>([]);
  const [cargandoSucursales, setCargandoSucursales] = useState<boolean>(false);
  const { invalidFields, isInvalidForm } = useOrderFormValidation(formData);
  // const prevContrato = usePrevious<string>(formData.contrato);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      idPedido: makeId(orderNumber),
    }));
  }, [orderNumber]);

  useEffect(() => {
    const valorDeclarado = calcValorDeclarado(orderTotal);

    setFormData((prevFormData) => ({
      ...prevFormData,
      valorDeclarado,
    }));
  }, [orderTotal]);

  useEffect(() => {
    const contrato = getContratoEntregaByPickupType(pickupType);

    setFormData((prevFormData) => ({
      ...prevFormData,
      contrato,
    }));
  }, [pickupType]);

  useEffect(() => {
    const pesoAforado = calcPesoAforado(
      formData.anchoCm,
      formData.altoCm,
      formData.largoCm,
    );

    setFormData((prevFormData) => ({
      ...prevFormData,
      pesoAforado,
    }));
  }, [formData.anchoCm, formData.altoCm, formData.largoCm]);

  useEffect(() => {
    let sucursal = sucursales.length === 0 ? null : sucursales[0];

    if (defaultIdSucursal > 0 && isFirstLoad && sucursales.length > 0) {
      const sucursalFinded = sucursales.find(
        (_sucursal) => _sucursal.id === defaultIdSucursal,
      );
      sucursal = sucursalFinded ? sucursalFinded : sucursal;
      setIsFirstLoad(false);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      sucursal,
    }));
  }, [sucursales]);

  useEffect(() => {
    if (zipcode === null || !formData.contrato) {
      return;
    }

    setCargandoSucursales(true);

    getSucursalesData({
      zipcode,
      contrato: formData.contrato,
    }).then((data) => {
      setSucursales(data);
      setCargandoSucursales(false);
    });
  }, [zipcode, formData.contrato]);

  useEffect(() => {
    onChangeFormData(formData);
  }, [formData]);

  const handleChange = (event: React.FormEvent<HTMLElement>) => {
    if (
      event.target instanceof HTMLInputElement ||
      event.target instanceof HTMLSelectElement
    ) {
      const { id, value } = event.target;

      setFormData((prevFormData) => ({ ...prevFormData, [id]: value }));
    }
  };

  const handleChangeSucursal = (event: React.FormEvent<HTMLElement>) => {
    if (!(event.target instanceof HTMLSelectElement)) {
      return;
    }
    const { value } = event.target;

    if (!value) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        sucursal: null,
      }));
      return;
    }

    const sucursal = sucursales.find(
      (sucursal) => sucursal.id == parseInt(value),
    );

    if (sucursal) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        sucursal,
      }));
    }
  };

  return (
    <>
      <FormField.Input
        id="idPedido"
        label="ID Pedido"
        placeholder=""
        value={formData.idPedido}
        onChange={handleChange}
        showHelpText={invalidFields.idPedido}
        helpText={invalidFields.idPedido ? 'Campo requerido' : ''}
        appearance={invalidFields.idPedido ? 'danger' : 'none'}
        tabIndex={1}
      />
      <FormField.Select
        id="contrato"
        name="contrato"
        label="Contrato"
        value={formData.contrato}
        onChange={handleChange}
        showHelpText={invalidFields.contrato}
        helpText={invalidFields.contrato ? 'Campo requerido' : ''}
        appearance={invalidFields.contrato ? 'danger' : 'none'}
        tabIndex={2}
      >
        <Select.Option key="0" label="- Seleccione un contrato -" value="" />
        {contratos.map((contrato) => (
          <Select.Option
            key={contrato.value}
            label={contrato.name}
            value={contrato.value}
          />
        ))}
      </FormField.Select>
      <FormFieldSelectSkeleton
        id="sucursal"
        label="Sucursal"
        name="sucursal"
        value={formData.sucursal ? formData.sucursal.id.toString() : ''}
        onChange={handleChangeSucursal}
        showHelpText={true}
        appearance={invalidFields.sucursal ? 'danger' : 'none'}
        skeleton={
          typeof cargandoSucursales == 'boolean'
            ? cargandoSucursales
            : undefined
        }
        tabIndex={3}
      >
        <Select.Option key="0" label="- Seleccione una sucursal -" value="" />
        {Array.from(sucursales, (sucursal) => (
          <Select.Option
            key={sucursal.id}
            label={getSucursalDescripcion(sucursal, defaultIdSucursal)}
            value={sucursal.id.toString()}
          />
        ))}
      </FormFieldSelectSkeleton>
      <FormField.Input
        id="valorDeclarado"
        label="Valor declarado"
        placeholder="1500"
        append={'$'}
        appendPosition="start"
        inputMode="numeric"
        value={formData.valorDeclarado}
        onChange={handleChange}
        showHelpText={true}
        helpText={
          !(formData.valorDeclarado > 0)
            ? 'Campo requerido'
            : '(Valor total - 40%) / 2'
        }
        appearance={!(formData.valorDeclarado > 0) ? 'danger' : 'none'}
        tabIndex={4}
      />
      <Box display="flex" flexDirection="column" gap="4">
        <Text>Peso (kg)</Text>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: '1fr 1fr',
            md: '1fr 1fr 1fr 1fr',
          }}
          gap="4"
        >
          <FormField.Input
            id="anchoCm"
            label="Ancho"
            placeholder="0.00"
            append={<Text color="neutral-textDisabled">cm</Text>}
            appendPosition="end"
            value={formData.anchoCm}
            onChange={handleChange}
            showHelpText={invalidFields.anchoCm}
            helpText={invalidFields.anchoCm ? 'Campo requerido' : ''}
            appearance={invalidFields.anchoCm ? 'danger' : 'none'}
            tabIndex={5}
          />
          <FormField.Input
            id="altoCm"
            label="Alto"
            placeholder="0.00"
            append={<Text color="neutral-textDisabled">cm</Text>}
            appendPosition="end"
            value={formData.altoCm}
            onChange={handleChange}
            showHelpText={invalidFields.altoCm}
            helpText={invalidFields.altoCm ? 'Campo requerido' : ''}
            appearance={invalidFields.altoCm ? 'danger' : 'none'}
            tabIndex={6}
          />
          <FormField.Input
            id="largoCm"
            label="Largo"
            placeholder="0.00"
            append={<Text color="neutral-textDisabled">cm</Text>}
            appendPosition="end"
            value={formData.largoCm}
            onChange={handleChange}
            showHelpText={invalidFields.largoCm}
            helpText={invalidFields.largoCm ? 'Campo requerido' : ''}
            appearance={invalidFields.largoCm ? 'danger' : 'none'}
            tabIndex={7}
          />
          <FormField.Input
            id="pesoAforado"
            label="Peso aforado"
            placeholder="0.00"
            append={<Text color="neutral-textDisabled">kg</Text>}
            appendPosition="end"
            onChange={handleChange}
            value={formData.pesoAforado}
            showHelpText={true}
            helpText={
              !(formData.pesoAforado > 0)
                ? 'Campo requerido'
                : 'Volumen * 350 / 1000'
            }
            appearance={!(formData.pesoAforado > 0) ? 'danger' : 'none'}
            tabIndex={8}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap="4">
        <CotizacionWidget
          cotizacion={cotizacion}
          valorDeclarado={formData.valorDeclarado}
          shippingCost={shippingCost}
          skeleton={typeof cotizando == 'boolean' ? cotizando : undefined}
        />
      </Box>
    </>
  );
};

export default EnvioForm;

import React, {
  ChangeEvent,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { DataList } from '@nimbus-ds/patterns';
import {
  Box,
  Icon,
  Text,
  Button,
  Accordion,
  Tag,
  Skeleton,
  Toggle,
  useToast,
  Spinner,
} from '@nimbus-ds/components';
import { BoxUnpackedIcon, PrinterIcon } from '@nimbus-ds/icons';
import {
  EnvioTrazasResponseDataType,
  findContratoLabel,
  getTrazas,
} from '../../services/andreani';
import ApplyShippingTrackingModal from '../ApplyShippingTrackingModal';
import ButtonPrintEnvio from '../ButtonPrintEnvio';

export type Envio = {
  id_envio: number;
  id_order: number;
  id_sucursal: number;
  sucursal: string;
  contrato: string;
  numero_andreani: string;
  andreani_response: string;
  ancho: number;
  alto: number;
  largo: number;
  costo_envio: number;
  date_created: string;
};

type ListEnviosItemProps = {
  envio: Envio;
  id_order: string;
  shipping_tracking_number: string;
  onClick?: MouseEventHandler<HTMLElement> | undefined;
};

const getTrazabilidad = async ({
  numero_andreani,
}: {
  numero_andreani: string;
}): Promise<EnvioTrazasResponseDataType | false> => {
  const response = await getTrazas(numero_andreani);

  if (!response.success || response.data === null) {
    return false;
  }

  return response.data;
};

const ListEnviosItem: React.FC<ListEnviosItemProps> = ({
  envio,
  id_order,
  shipping_tracking_number,
}: ListEnviosItemProps) => {
  const { addToast } = useToast();

  const isAplicado = shipping_tracking_number == envio.numero_andreani;

  const [showTrazabilidad, setShowTrazabilidad] = useState<boolean>(false);
  const [trazas, setTrazas] = useState<EnvioTrazasResponseDataType | false>(
    false,
  );
  const [loadingTrazas, setLoadingTrazas] = useState<boolean>(false);
  const [openModalNumeroDeSeguimiento, setOpenModalNumeroDeSeguimiento] =
    useState(false);

  const showModalNumeroDeSeguimiento = () => {
    setOpenModalNumeroDeSeguimiento(true);
  };

  const hideModalNumeroDeSeguimiento = () => {
    setOpenModalNumeroDeSeguimiento(false);
  };

  const loadTrazabilidad = async () => {
    if (loadingTrazas) {
      return;
    }

    setLoadingTrazas(true);

    let trazas: EnvioTrazasResponseDataType | false = false;

    try {
      trazas = await getTrazabilidad({
        numero_andreani: envio.numero_andreani,
      });
    } catch (error) {
      trazas = false;
    }

    if (trazas === false) {
      addToast({
        id: 'toast-error',
        type: 'danger',
        text: 'No se pudo obtener la trazabilidad',
        duration: 8000,
      });
    }

    setTrazas(trazas);
    setLoadingTrazas(false);
  };

  useEffect(() => {
    if (showTrazabilidad) {
      loadTrazabilidad();
    }
  }, [showTrazabilidad]);

  const handleTrazabilidad = async (e: ChangeEvent<HTMLInputElement>) => {
    // const active = e.target.checked;
    setShowTrazabilidad(e.target.checked);
  };

  const subtitle = `${findContratoLabel(envio.contrato)} - ${formatDate(
    envio.date_created,
  )}`;

  const title =
    envio.numero_andreani + (isAplicado ? ' (aplicado al pedido)' : '');

  return (
    <Accordion.Item index={`list-envios-item-${envio.id_envio.toString()}`}>
      <Accordion.Header
        icon={<BoxUnpackedIcon size={18} />}
        subtitle={subtitle}
        title={title}
      />
      <Accordion.Body>
        <Box padding="2" width="100%">
          <Box display="flex" gap="1" mt="2">
            {envio.sucursal && <Tag>{envio.sucursal}</Tag>}
            <Tag>$ {new Intl.NumberFormat().format(envio.costo_envio)}</Tag>
          </Box>
          <Box display="flex" gap="1" mt="4" mb="4">
            <ButtonPrintEnvio
              numeroAndreani={envio.numero_andreani}
              toast={addToast}
              loading={
                <>
                  Imprimiendo...
                  <Spinner color="currentColor" size="small" />
                </>
              }
            >
              Imprimir <Icon color="currentColor" source={<PrinterIcon />} />
            </ButtonPrintEnvio>
            {!isAplicado && (
              <Button
                appearance="neutral"
                onClick={showModalNumeroDeSeguimiento}
              >
                Aplicar número de seguimiento
                <Icon color="currentColor" source={<PrinterIcon />} />
              </Button>
            )}
            <Toggle
              name="toggle-trazabilidad"
              label="Trazabilidad"
              onChange={handleTrazabilidad}
              readOnly={loadingTrazas}
            />
          </Box>
          {showTrazabilidad && (
            <DataList>
              {loadingTrazas && (
                <DataList.Row key={1} gap="1" topDivider={false}>
                  <Box display="flex" justifyContent="space-between">
                    <Skeleton height="1rem" width="9rem" borderRadius="2" />
                    <Skeleton height="1rem" width="6rem" borderRadius="2" />
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Skeleton height="1rem" width="7rem" borderRadius="2" />
                  </Box>
                </DataList.Row>
              )}
              {!loadingTrazas && !trazas && (
                <DataList.Row key={1} gap="1" topDivider={false}>
                  <Box display="flex" justifyContent="space-between">
                    <Text fontWeight="medium" color="primary-interactive">
                      No se encontraron trazas
                    </Text>
                  </Box>
                </DataList.Row>
              )}
              {!loadingTrazas && trazas && (
                <DataList>
                  {trazas.eventos.map((evento, i) => (
                    <DataList.Row key={i} gap="1" topDivider={false}>
                      <Box display="flex" justifyContent="space-between">
                        <Text fontWeight="medium" color="primary-interactive">
                          {evento.Estado}
                        </Text>
                        <Text>{formatDate(evento.Fecha)}</Text>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Text>{evento.Sucursal}</Text>
                      </Box>
                    </DataList.Row>
                  ))}
                </DataList>
              )}
            </DataList>
          )}
        </Box>

        <ApplyShippingTrackingModal
          open={openModalNumeroDeSeguimiento}
          id_order={id_order}
          tracking_number={envio.numero_andreani}
          tracking_url={`https://andreani.com/#!/informacionEnvio/${envio.numero_andreani}`}
          onDismiss={hideModalNumeroDeSeguimiento}
        />
      </Accordion.Body>
    </Accordion.Item>
  );
};

/**
 * Función que devuelve la fecha en formato dd/mm/yyyy
 *
 * @param string date
 * @return string
 */
const formatDate = (date: string) => {
  const dateObj = new Date(date);

  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();
  const hour = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  return `${day}/${month}/${year} ${hour}:${minutes}hs`;
};

export default ListEnviosItem;

import { connect, iAmReady } from '@tiendanube/nexo/helpers';
import { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import nexo from './nexoClient';
import NexoSyncRoute from './NexoSyncRoute';
import { OrdersPage, OrderPage } from './pages';
import { DarkModeProvider, BaseLayout } from './components';

import { ToastProvider } from '@nimbus-ds/components';

function App() {
  const [isConnect, setIsConnect] = useState(false);
  const [msg, setMsg] = useState('');

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setIsConnect(true);
      return;
    }

    setMsg('conectando...');
    connect(nexo).then(
      () => {
        setMsg('conectado!');
        setIsConnect(true);
        iAmReady(nexo);
      },
      (err) => {
        setMsg('errores!');
        console.log('Errores', err);
      },
    );
  }, []);

  if (!isConnect) return <div>{msg}</div>;

  return (
    <DarkModeProvider>
      <BrowserRouter>
        <ToastProvider>
          <BaseLayout>
            <NexoSyncRoute>
              <Switch>
                <Route path="/" exact>
                  <OrdersPage />
                </Route>
                <Route path="/orders">
                  <Switch>
                    <Route path="/orders" exact>
                      <OrdersPage />
                    </Route>
                    <Route path="/orders/:orderId">
                      <OrderPage />
                    </Route>
                  </Switch>
                </Route>
              </Switch>
            </NexoSyncRoute>
          </BaseLayout>
        </ToastProvider>
      </BrowserRouter>
    </DarkModeProvider>
  );
}

export default App;

import { useState, useEffect } from 'react';
import {
  OrderPageFormDataType,
  DataInvalidType,
} from './useOrderFormValidation.types';

const requiredFields = [
  'idPedido',
  'altoCm',
  'anchoCm',
  'contrato',
  'largoCm',
  // 'pesoAforado',
  'sucursal',
  // 'valorDeclarado',
];

const getInvalidFields = (formData: OrderPageFormDataType): string[] => {
  const invalidFields: string[] = [];

  requiredFields.forEach(function (field) {
    if (['altoCm', 'anchoCm', 'largoCm'].includes(field)) {
      if (parseFloat(formData[field]) <= 0) {
        invalidFields.push(field);
      }
      return;
    }

    if (field == 'sucursal' && formData[field] != null) {
      return;
    }

    if (
      formData[field] != null &&
      typeof formData[field] == 'string' &&
      formData[field].trim() !== ''
    ) {
      return;
    }

    invalidFields.push(field);
  });

  return invalidFields;
};

const isValidField = (
  field: string,
  formData: OrderPageFormDataType,
): boolean => {
  const invalidFields = getInvalidFields(formData);

  return !invalidFields.includes(field);
};

const isValidForm = (formData: OrderPageFormDataType): boolean => {
  const invalidFields = getInvalidFields(formData);

  return invalidFields.length === 0;
};

const useOrderFormValidation = (defaultFormData: OrderPageFormDataType) => {
  const [invalidFields, setInvalidFields] = useState<DataInvalidType>({
    idPedido: false,
    altoCm: false,
    anchoCm: false,
    contrato: false,
    largoCm: false,
    pesoAforado: false,
    sucursal: false,
    valorDeclarado: false,
  });
  const [isInvalidForm, setIsInvalidForm] = useState<boolean>(false);

  useEffect(() => {
    if (defaultFormData == null) return;
    const data = {
      idPedido: !isValidField('idPedido', defaultFormData),
      altoCm: !isValidField('altoCm', defaultFormData),
      anchoCm: !isValidField('anchoCm', defaultFormData),
      contrato: !isValidField('contrato', defaultFormData),
      largoCm: !isValidField('largoCm', defaultFormData),
      pesoAforado: !isValidField('pesoAforado', defaultFormData),
      sucursal: !isValidField('sucursal', defaultFormData),
      valorDeclarado: !isValidField('valorDeclarado', defaultFormData),
    };
    setInvalidFields(data);
    setIsInvalidForm(!isValidForm(defaultFormData));
  }, [defaultFormData]);

  return { invalidFields, isInvalidForm };
};

export default useOrderFormValidation;

import { IconProps, Tag, TagProperties, Text } from '@nimbus-ds/components';
import { CashIcon } from '@nimbus-ds/icons';

interface AndreaniStatusProps {
  title: string;
}

const AndreaniStatus: React.FC<AndreaniStatusProps> = ({
  title,
}: AndreaniStatusProps) => {
  return (
    <>
      <span
        style={{
          backgroundColor: 'red',
          color: 'white',
          padding: '2px 8px',
          textAlign: 'center',
          borderRadius: 9999,
          fontSize: 12,
        }}
      >
        <b>A</b>ndreani
      </span>
      {title && <Text fontSize="caption">{title}</Text>}
    </>
  );
};

export default AndreaniStatus;

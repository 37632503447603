import { useEffect, useState } from 'react';
import { Tag, TagProperties, Text } from '@nimbus-ds/components';
import { TruckIcon, StoreIcon, IconProps } from '@nimbus-ds/icons';
import TagStatus from '../TagStatus';

function getShippingAppearance(
  shipping_status: string,
  shipping_pickup_type: string,
): TagProperties['appearance'] {
  const shippingAppearances: Record<string, TagProperties['appearance']> = {
    ship_any: 'neutral',
    ship_unpacked: 'neutral',
    ship_unfulfilled: 'primary',
    ship_unshipped: 'primary',
    ship_fulfilled: 'success',
    ship_shipped: 'success',

    pickup_any: 'neutral',
    pickup_unpacked: 'neutral',
    pickup_unfulfilled: 'primary',
    pickup_unshipped: 'primary',
    pickup_fulfilled: 'success',
    pickup_shipped: 'success',
  };

  return (
    shippingAppearances[shipping_pickup_type + '_' + shipping_status] ||
    'warning'
  );
}

function getShippingLabel(
  shipping_status: string,
  shipping_pickup_type: string,
): string {
  const shippingLabels: Record<string, string> = {
    ship_any: 'Pendiente',
    ship_unpacked: 'Por empaquetar',
    ship_unfulfilled: 'Por enviar',
    ship_unshipped: 'Por enviar',
    ship_fulfilled: 'Enviada',
    ship_shipped: 'Enviada',

    pickup_any: 'Pendiente',
    pickup_unpacked: 'Por empaquetar',
    pickup_unfulfilled: 'Por retirar',
    pickup_unshipped: 'Por retirar',
    pickup_fulfilled: 'Enviada',
    pickup_shipped: 'Enviada',
  };

  return (
    shippingLabels[shipping_pickup_type + '_' + shipping_status] ||
    'Envío pendiente'
  );
}

function ShippingIcon({
  pickup_type,
  ...props
}: {
  pickup_type: string;
  size: number;
}) {
  const icons: Record<string, React.FC<IconProps>> = {
    ship: TruckIcon,
    pickup: StoreIcon,
  };

  const Icon = icons[pickup_type] || TruckIcon;

  return <Icon {...props} />;
}

interface ShippingStatusProps {
  status: string;
  pickup_type: string;
  carrier_name: string;
}

const ShippingStatus: React.FC<ShippingStatusProps> = ({
  status,
  pickup_type,
  carrier_name,
}: ShippingStatusProps) => {
  const [isMounted, setIsMounted] = useState(false);
  const [appearance, setAppearance] =
    useState<TagProperties['appearance']>('warning');
  const [label, setLabel] = useState<string>('Envío pendiente');

  useEffect(() => {
    setAppearance(getShippingAppearance(status, pickup_type));
    setLabel(getShippingLabel(status, pickup_type));
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return (
    <>
      <TagStatus
        appearance={appearance}
        title={label}
        subtitle={carrier_name}
        icon={<ShippingIcon size={12} pickup_type={pickup_type} />}
      />
    </>
  );
};

export default ShippingStatus;

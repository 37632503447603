type OrderResponseType = {
  data: Order;
};

type Order = {
  id: number;
  token: string;
  store_id: string;
  contact_email: string;
  contact_name: string;
  contact_phone: string;
  contact_identification: string;
  shipping_min_days: number;
  shipping_max_days: number;
  billing_name: string;
  billing_phone: string;
  billing_address: string;
  billing_number: string;
  billing_floor: string;
  billing_locality: string;
  billing_zipcode: string;
  billing_city: string;
  billing_province: string;
  billing_country: string;
  shipping_cost_owner: string;
  shipping_cost_customer: string;
  coupon?: null[] | null;
  promotional_discount: PromotionalDiscount;
  subtotal: number;
  discount: string;
  discount_coupon: string;
  discount_gateway: string;
  total: number;
  total_usd: string;
  checkout_enabled: boolean;
  weight: string;
  currency: string;
  language: string;
  gateway: string;
  gateway_id: string;
  gateway_name: string;
  shipping: string;
  shipping_option: string;
  shipping_option_code: string;
  shipping_option_reference: string;
  shipping_pickup_details: ShippingPickupDetails;
  shipping_tracking_number?: null;
  shipping_tracking_url?: null;
  shipping_store_branch_name?: null;
  shipping_store_branch_extra?: null;
  shipping_pickup_type: PickupType;
  shipping_suboption: ShippingSuboption;
  extra: Extra;
  storefront: string;
  note: string;
  created_at: string;
  updated_at: string;
  completed_at: CompletedAt;
  next_action: string;
  payment_details: PaymentDetails;
  attributes?: null[] | null;
  customer: Customer;
  products: ProductsEntity[];
  fulfillments?: null;
  number: number;
  cancel_reason?: null;
  owner_note?: null;
  cancelled_at?: null;
  closed_at?: null;
  read_at?: null;
  status: string;
  payment_status: string;
  gateway_link: string;
  has_shippable_products: boolean;
  shipping_carrier_name: string;
  shipping_address: ShippingAddress;
  shipping_status: string;
  shipped_at?: null;
  paid_at: string;
  landing_url: string;
  client_details: ClientDetails;
  app_id?: null;
};
export enum PickupType {
  ship = 'ship',
  pick = 'pick',
}
type PromotionalDiscount = {
  id?: null;
  store_id: number;
  order_id: string;
  created_at: string;
  total_discount_amount: string;
  contents?: null[] | null;
  promotions_applied?: null[] | null;
};
type ShippingPickupDetails = {
  name: string;
  address: Address;
  hours?: HoursEntity[] | null;
};
type Address = {
  address: string;
  number: string;
  floor?: null;
  locality: string;
  city: string;
  province: string;
  zipcode: string;
  country: string;
  latitude: string;
  longitude: string;
  phone: string;
};
type HoursEntity = {
  day: number;
  start: string;
  end: string;
};
type ShippingSuboption = {
  id: string;
};
// eslint-disable-next-line @typescript-eslint/ban-types
type Extra = {};
type CompletedAt = {
  date: string;
  timezone_type: number;
  timezone: string;
};
type PaymentDetails = {
  method: string;
  credit_card_company: string;
  installments: string;
};
type Customer = {
  id: number;
  name: string;
  email: string;
  identification: string;
  phone: string;
  note?: null;
  default_address: AddressesEntityOrDefaultAddress;
  addresses?: AddressesEntityOrDefaultAddress[] | null;
  billing_name: string;
  billing_phone: string;
  billing_address: string;
  billing_number: string;
  billing_floor: string;
  billing_locality: string;
  billing_zipcode: string;
  billing_city: string;
  billing_province: string;
  billing_country: string;
  extra: Extra;
  total_spent: string;
  total_spent_currency: string;
  last_order_id: number;
  active: boolean;
  first_interaction: string;
  created_at: string;
  updated_at: string;
  accepts_marketing: boolean;
  accepts_marketing_updated_at: string;
};
type AddressesEntityOrDefaultAddress = {
  address?: null;
  city: string;
  country: string;
  created_at: string;
  default: boolean;
  floor: string;
  id: number;
  locality: string;
  name: string;
  number: string;
  phone: string;
  province: string;
  updated_at: string;
  zipcode: string;
};
type ProductsEntity = {
  id: number;
  depth: string;
  height: string;
  name: string;
  price: number;
  compare_at_price: string;
  product_id: number;
  image: Image;
  quantity: string;
  free_shipping: boolean;
  weight: string;
  width: string;
  variant_id: string;
  variant_values?: string[] | null;
  properties?: null[] | null;
  sku: string;
  barcode?: null;
};
type Image = {
  id: number;
  product_id: number;
  src: string;
  position: number;
  alt?: null[] | null;
  created_at: string;
  updated_at: string;
};
type ShippingAddress = {
  address: string;
  city: string;
  country: string;
  created_at: string;
  default: boolean;
  floor: string;
  id: number;
  locality: string;
  name: string;
  number: string;
  phone: string;
  province: string;
  updated_at: string;
  zipcode: string;
  customs?: null;
};
type ClientDetails = {
  browser_ip: string;
  user_agent: string;
};

export type {
  OrderResponseType,
  Order,
  PromotionalDiscount,
  ShippingPickupDetails,
  Address,
  HoursEntity,
  ShippingSuboption,
  Extra,
  CompletedAt,
  PaymentDetails,
  Customer,
  AddressesEntityOrDefaultAddress,
  ProductsEntity,
  Image,
  ShippingAddress,
  ClientDetails,
};

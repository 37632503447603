import React, { useEffect, useState } from 'react';
import { InteractiveList } from '@nimbus-ds/patterns';
import ListEnviosItem from '../ListEnviosItem';
import { Envio } from '../../types/envios';
import { getEnviosByOrderId } from '../../services/tiendanube';
import { Card, Text, Skeleton, Accordion } from '@nimbus-ds/components';
import ListEnviosItemSkeleton from '../ListEnviosItemSkeleton';

type ListEnviosProps = {
  title: string;
  id_order: string;
  shipping_tracking_number: string;
};

const getEnvios = async ({
  id_order,
}: {
  id_order: string;
}): Promise<Envio[]> => {
  const response = await getEnviosByOrderId(id_order);

  if (!response.success || response.data === null) {
    return [];
  }

  return response.data;
};

const ListEnvios: React.FC<ListEnviosProps> = ({
  title,
  id_order,
  shipping_tracking_number,
}: ListEnviosProps) => {
  const [envios, setEnvios] = useState<Envio[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getEnvios({ id_order }).then((_envios) => {
      setEnvios(_envios);
      setLoading(false);
    });
  }, []);

  let content;

  if (loading) {
    content = (
      <InteractiveList>
        <ListEnviosItemSkeleton />
        <ListEnviosItemSkeleton />
      </InteractiveList>
    );
  } else {
    content =
      envios.length > 0 ? (
        <Accordion>
          {Array.from(envios, (envio, i) => (
            <ListEnviosItem
              key={i}
              envio={envio}
              id_order={id_order}
              shipping_tracking_number={shipping_tracking_number}
            />
          ))}
        </Accordion>
      ) : (
        <Text>No hay envíos creados</Text>
      );
  }

  return (
    <Card>
      <Card.Header title={title} />
      <Card.Body>{content}</Card.Body>
    </Card>
  );
};

export default ListEnvios;

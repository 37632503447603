import { Button, ButtonProps, Spinner } from '@nimbus-ds/components';

type ButtonsWithSpinnerProps = {
  loading: boolean;
  visible?: boolean;
} & ButtonProps;

const ButtonWithSpinner: React.FC<ButtonsWithSpinnerProps> = (
  props: ButtonsWithSpinnerProps,
) => {
  if (props.visible === false) {
    return null;
  }

  const { loading, ...rest } = props;

  if (!props.loading) {
    return <Button {...rest} />;
  }

  return (
    <Button {...rest}>
      <Spinner color="currentColor" size="small" />
      {rest.children}
    </Button>
  );
};

export default ButtonWithSpinner;

import React, { useEffect, useState } from 'react';
import { Text, Button, Toggle, useToast, Modal } from '@nimbus-ds/components';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { FulfillResponseType, fulfill } from '../../services/tiendanube';

type ApplyShippingTrackingModalProps = {
  open: boolean;
  tracking_number: string;
  tracking_url: string;
  id_order: string;
  onDismiss: () => void;
};

const ApplyShippingTrackingModal: React.FC<ApplyShippingTrackingModalProps> = ({
  open,
  tracking_number,
  tracking_url,
  id_order,
  onDismiss,
}: ApplyShippingTrackingModalProps) => {
  const { addToast } = useToast();

  const [applying, setApplying] = useState(false);

  const [notifyCustomer, setNotifyCustomer] = useState(false);

  const handleSetNumeroDeSeguimiento = async () => {
    setApplying(true);

    let response: false | FulfillResponseType = false;

    try {
      response = await fulfill(id_order, {
        shipping_tracking_number: tracking_number,
        shipping_tracking_url: tracking_url,
        notify_customer: notifyCustomer,
      });
    } catch (error) {
      response = false;
    }

    if (!response || !response.success) {
      addToast({
        id: 'toast-error',
        type: 'danger',
        text: 'No se pudo aplicar el número de seguimiento',
        duration: 8000,
      });
      setApplying(false);
      return;
    }

    addToast({
      id: 'toast-success',
      type: 'success',
      text: 'Número de seguimiento aplicado',
      duration: 8000,
    });

    onDismiss();
    setApplying(false);
  };

  const fnEmpty = () => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  };

  return (
    <Modal open={open} onDismiss={!applying ? onDismiss : fnEmpty}>
      <Modal.Header title="Aplicar número de seguimiento a la venta" />
      <Modal.Body>
        <Text fontSize="base" lineHeight="base" textAlign="left">
          Se aplicará el número de seguimiento al pedido. <br />
          <br />
        </Text>
        <Toggle
          name="send-notificacion"
          label="Enviar notificación al usuario"
          checked={notifyCustomer}
          onClick={() => setNotifyCustomer(!notifyCustomer)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="neutral" disabled={applying} onClick={onDismiss}>
          Cancelar
        </Button>
        <ButtonWithSpinner
          appearance="primary"
          onClick={handleSetNumeroDeSeguimiento}
          disabled={applying}
          loading={applying}
        >
          {applying ? 'Aplicando' : 'Aplicar'}
        </ButtonWithSpinner>
      </Modal.Footer>
    </Modal>
  );
};

export default ApplyShippingTrackingModal;

import { useEffect, useState } from 'react';
import { TagProperties } from '@nimbus-ds/components';
import { CashIcon, ExclamationTriangleIcon } from '@nimbus-ds/icons';
import TagStatus from '../TagStatus';
import PaymentStatus from '../PaymentStatus';

function getOrderAppearance(
  payment_status: string,
): TagProperties['appearance'] {
  const paymentAppearances: Record<string, TagProperties['appearance']> = {
    paid: 'success',
    pending: 'warning',
    refunded: 'danger',
    voided: 'danger',
  };

  return paymentAppearances[payment_status] || 'warning';
}

function getOrderLabel(payment_status: string): string {
  const paymentLabels: Record<string, string> = {
    paid: 'Pago recibido',
    pending: 'Pago en proceso',
    refunded: 'Pago devuelto',
    voided: 'Pago rechazado',
  };

  return paymentLabels[payment_status] || 'Pago pendiente';
}

interface OrderStatusProps {
  status: string;
  payment_status: string;
  gateway_name: string;
}

const OrderStatus: React.FC<OrderStatusProps> = ({
  status,
  payment_status,
  gateway_name,
}: OrderStatusProps) => {
  const [isMounted, setIsMounted] = useState(false);
  const [appearance, setAppearance] =
    useState<TagProperties['appearance']>('warning');
  const [label, setLabel] = useState<string>('Pago pendiente');

  useEffect(() => {
    setAppearance(getOrderAppearance(status));
    setLabel(getOrderLabel(status));

    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  if (status == 'cancelled') {
    return (
      <TagStatus
        appearance="neutral"
        title="Venta cancelada"
        subtitle={gateway_name}
        icon={<ExclamationTriangleIcon size={12} />}
      />
    );
  }

  return <PaymentStatus status={payment_status} gateway_name={gateway_name} />;
};

export default OrderStatus;

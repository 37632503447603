import React, { useEffect, useState } from 'react';
import nexo from '../../nexoClient';
import {
  ButtonPrintEnvio,
  ResponsiveComponent,
  TableSkeleton,
} from '../../components';
import {
  Page,
  Layout,
  DataTable,
  DataList,
  SideModal,
  EmptyMessage,
} from '@nimbus-ds/patterns';
import {
  Box,
  Button,
  Checkbox,
  Icon,
  IconButton,
  Input,
  Link,
  Table,
  Tag,
  Text,
  Skeleton,
  Chip,
  useToast,
  Badge,
  Spinner,
} from '@nimbus-ds/components';
import {
  ChevronLeftIcon,
  EllipsisIcon,
  PrinterIcon,
  RedoIcon,
  SearchIcon,
  TruckIcon,
} from '@nimbus-ds/icons';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import { getOrders } from '../../services/tiendanube';
import {
  OrdersResponseType,
  Orders as OrdersType,
  AndreaniType as OrdersAndreaniEnviosType,
} from '../../services/Orders.types';
import { ShippingStatus } from '../../components';
import OrderStatus from '../../components/OrderStatus';
import AndreaniStatus from '../../components/AndreaniStatus';
import config from '../../config';
import { findContratoLabel } from '../../services/andreani';

const OrdersPage: React.FC = () => {
  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: 'Volver al inicio' });
  }, []);

  const { addToast } = useToast();

  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<OrdersType[]>([]);
  const [countOrders, setCountOrders] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);
  const [query, setQuery] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [checkedAll, setCheckedAll] = useState(false);
  const [openEnvios, setOpenEnvios] = useState(false);
  const [sidebarEnvios, setSidebarEnvios] = useState<
    OrdersAndreaniEnviosType[]
  >([]);

  const handleOpenEnvios = (envios: OrdersAndreaniEnviosType[]) => {
    setOpenEnvios(!openEnvios);
    setSidebarEnvios(envios);
  };

  const handleCloseEnvios = () => {
    setOpenEnvios(false);
    setSidebarEnvios([]);
  };

  useEffect(() => {
    handleLoadOrders();
  }, []);

  useEffect(() => {
    handleLoadOrders();
  }, [activePage, query]);

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') {
      return true;
    }
    event.preventDefault();

    const target = event.target as HTMLInputElement;

    setActivePage(1);
    setQuery(target.value.trim());
    target.value = '';
  };

  const handleLoadOrders = () => {
    setLoading(true);
    setOrders([]);
    calcPagination(0);
    getOrders({
      per_page: pageLimit,
      page: activePage,
      q: query,
      codigoCliente: config.ANDREANI_CLIENT,
    }).then((response: OrdersResponseType) => {
      if (!response) {
        addToast({
          id: 'toast-error',
          type: 'danger',
          text: 'No se pudieron cargar los pedidos',
          duration: 4000,
        });
        setLoading(false);
        return;
      }
      setOrders(response.data ?? []);
      calcPagination(response.total_count);
      setLoading(false);
    });
  };

  const calcPagination = (totalCount: number) => {
    setCountOrders(totalCount);
    setPageCount(Math.ceil(totalCount / pageLimit));
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const tableHeader = (
    <DataTable.Header
      checkbox={{
        name: 'check-all-rows',
        defaultChecked: checkedAll,
        // onChange: (event) => {
        //   setCheckedAll(event.target.checked);
        // },
        readOnly: true,
      }}
    >
      <Table.Cell width="50px">Venta</Table.Cell>
      <Table.Cell width="80px">Fecha</Table.Cell>
      <Table.Cell width="80px">Cliente</Table.Cell>
      <Table.Cell width="80px">Total</Table.Cell>
      {/* <Table.Cell width="100px">Productos</Table.Cell> */}
      <Table.Cell width="100px">Estado del pago</Table.Cell>
      <Table.Cell width="100px">Estado del envío</Table.Cell>
      <Table.Cell width="110px">Andreani</Table.Cell>
      {/* <Table.Cell width="40px">Acciones</Table.Cell> */}
    </DataTable.Header>
  );

  const tableFooter = !loading && countOrders > 0 && (
    <DataTable.Footer
      itemCount={
        !loading
          ? `Mostrando ${activePage}-${pageLimit} elementos de ${countOrders}`
          : ''
      }
      pagination={{
        pageCount: pageCount,
        activePage: activePage,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onPageChange: async (newPage) => {
          if (activePage == newPage) {
            return;
          }

          console.log('Page changed', activePage, newPage);

          setActivePage(newPage);
        },
      }}
    />
  );

  const mobileContent = (
    <>
      <Box px="4">
        <Link as="button" onClick={handleEditMode}>
          {editMode ? 'Cancelar' : 'Editar'}
        </Link>
      </Box>
      <DataList>
        {Array.from(orders, (_, index) => (
          <DataList.Row key={index} flexDirection="row" gap="2">
            {editMode && (
              <Checkbox
                name={`check-${index}`}
                checked={false}
                readOnly={true}
              />
            )}
            <Box display="flex" flexDirection="column" flex="1 1 auto">
              <Box display="flex" justifyContent="space-between" mb="2">
                <Text color="primary-interactive">#123</Text>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Text>05 de Sep</Text>
                <Text>Alejo Sotelo</Text>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Text>$10.000,00</Text>
                {/* <Text>Ver productos</Text> */}
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt="2"
              >
                <Tag>Pago recibido</Tag>
                <IconButton source={<EllipsisIcon />} size="2rem" />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt="2"
              >
                <Tag>Por enviar</Tag>
                <IconButton source={<EllipsisIcon />} size="2rem" />
              </Box>
            </Box>
          </DataList.Row>
        ))}
      </DataList>
    </>
  );

  const optionsDate: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
  };

  const optionsDateTime: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
  };

  const desktopContent = (
    <>
      <DataTable header={tableHeader} footer={tableFooter}>
        {Array.from(orders, (order: OrdersType, index) => {
          const paymentStatus =
            order.status == 'cancelled' ? 'cancelled' : order.payment_status;
          return (
            <DataTable.Row
              key={index}
              backgroundColor={{
                rest: 'neutral-background',
                hover: 'neutral-surface',
              }}
              checkbox={{
                name: `check-${index}`,
                checked: false,
                readOnly: true,
              }}
            >
              <Table.Cell>
                <Link as="a" href={`/orders/${order.id}`}>
                  <Text color="primary-interactive">#{order.number}</Text>
                </Link>
              </Table.Cell>
              <Table.Cell>
                {new Intl.DateTimeFormat('es-ES', optionsDate).format(
                  new Date(order.created_at),
                )}
              </Table.Cell>
              <Table.Cell>{order.contact_name}</Table.Cell>
              <Table.Cell>
                $ {new Intl.NumberFormat().format(parseFloat(order.total))}
              </Table.Cell>
              <Table.Cell>
                <OrderStatus
                  status={order.status}
                  payment_status={paymentStatus}
                  gateway_name={order.gateway_name}
                />
              </Table.Cell>
              <Table.Cell>
                {order.status != 'cancelled' && (
                  <ShippingStatus
                    status={order.shipping_status}
                    pickup_type={order.shipping_pickup_type}
                    carrier_name={order.shipping_carrier_name}
                  />
                )}
              </Table.Cell>
              <Table.Cell>
                {order.andreani.envios.length == 1 && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Box>
                      <ButtonPrintEnvio
                        numeroAndreani={
                          order.andreani.envios[0].numero_andreani
                        }
                        toast={addToast}
                        loading={<Spinner color="currentColor" size="small" />}
                      >
                        <Icon color="currentColor" source={<PrinterIcon />} />
                      </ButtonPrintEnvio>
                    </Box>
                    <Box>
                      <AndreaniStatus
                        title={
                          order.andreani.envios[0].estado == ''
                            ? 'Envío creado'
                            : order.andreani.envios[0].estado
                        }
                      />
                    </Box>
                  </Box>
                )}
                {order.andreani.envios.length > 1 && (
                  <>
                    <Button
                      onClick={() => handleOpenEnvios(order.andreani.envios)}
                    >
                      Ver
                      <Badge
                        count={order.andreani.envios.length}
                        theme="surface"
                      />
                    </Button>
                  </>
                )}
              </Table.Cell>
              {/* <Table.Cell>
                <IconButton source={<EllipsisIcon />} size="2rem" />
              </Table.Cell> */}
            </DataTable.Row>
          );
        })}
        {countOrders == 0 && (
          <DataTable.Row
            key={0}
            checkbox={{
              name: `check-0`,
              checked: false,
              disabled: true,
            }}
          >
            <td colSpan={7}>
              <br />
              <EmptyMessage
                title="Sin pedidos"
                text="No se encontraron pedidos"
                icon={<SearchIcon size={32} />}
                actions={
                  <Link appearance="primary" onClick={handleLoadOrders}>
                    <Icon color="currentColor" source={<RedoIcon />} />
                    Recargar página
                  </Link>
                }
              />
              <br />
            </td>
          </DataTable.Row>
        )}
      </DataTable>

      <SideModal
        onRemove={handleCloseEnvios}
        open={openEnvios}
        maxWidth={{ xs: '100%', md: '340px', lg: '540px' }}
        title="Envíos Andreani"
        headerIcon={<Icon color="primary-textHigh" source={<TruckIcon />} />}
        padding="base"
        paddingHeader="none"
        paddingBody="none"
        paddingFooter="none"
        headerAction={
          <Box display="flex" alignItems="center" gap="1">
            <Icon color="primary-textHigh" source={<ChevronLeftIcon />} />
          </Box>
        }
      >
        <Box
          borderColor="neutral-interactive"
          height="100%"
          boxSizing="border-box"
          display="flex"
          flexDirection="column"
        >
          <Table>
            <Table.Head>
              <Table.Row backgroundColor="neutral-surface">
                <Table.Cell as="th" width="80px">
                  Número envío
                </Table.Cell>
                <Table.Cell as="th" width="80px">
                  Fecha
                </Table.Cell>
                <Table.Cell as="th" width="70px">
                  Estado
                </Table.Cell>
                <Table.Cell as="th" width="30px">
                  Imprimir
                </Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {sidebarEnvios.map((envio, index) => (
                <Table.Row
                  key={index}
                  backgroundColor={{
                    rest: 'neutral-background',
                    hover: 'neutral-surface',
                  }}
                >
                  <Table.Cell>
                    <Text color="neutral-textLow" fontSize="base">
                      {envio.numero_andreani}
                    </Text>
                    <Text color="neutral-textLow" fontSize="caption">
                      {findContratoLabel(envio.contrato)}
                    </Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text color="neutral-textLow" fontSize="caption">
                      {new Intl.DateTimeFormat('es-ES', optionsDateTime).format(
                        new Date(envio.date_created),
                      )}
                    </Text>
                  </Table.Cell>
                  <Table.Cell>
                    <AndreaniStatus
                      title={envio.estado == '' ? 'Envío creado' : envio.estado}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <ButtonPrintEnvio
                      numeroAndreani={envio.numero_andreani}
                      toast={addToast}
                      loading={<Spinner color="currentColor" size="small" />}
                    >
                      <Icon color="currentColor" source={<PrinterIcon />} />
                    </ButtonPrintEnvio>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Box>
      </SideModal>
    </>
  );

  const desktopContentSkeleton = (
    <TableSkeleton
      header={tableHeader}
      footer={tableFooter}
      cols={7}
      rows={10}
    />
  );

  return (
    <>
      <Page maxWidth="1200px">
        <Page.Header title="Pedidos">
          <Box display="flex" flexDirection="column" gap="2">
            <Box display="flex" gap="1">
              <Input.Search
                name="query"
                onKeyUp={handleSearch}
                defaultValue={query}
                placeholder="Buscar por número o monto exacto de la venta, nombre o e-mail del cliente, nombre de quien retira"
              />
              <Button onClick={handleLoadOrders}>
                <Icon color="currentColor" source={<RedoIcon />} />
              </Button>
              {/* <Button onClick={handleLoadOrders}>
                <Icon color="currentColor" source={<SlidersIcon />} />
              </Button> */}
            </Box>
            <Box display="flex" gap="2" alignItems="center">
              {query.length > 0 && (
                <Chip
                  removable={true}
                  text={query}
                  onClick={() => setQuery('')}
                />
              )}
              {loading ? (
                <Skeleton height="1rem" width="4rem" borderRadius="2" />
              ) : (
                <Text>{countOrders} ventas</Text>
              )}
            </Box>
          </Box>
        </Page.Header>
        <Page.Body px={{ xs: 'none', md: '6' }}>
          <Layout columns="1">
            {loading ? (
              <ResponsiveComponent
                desktopContent={desktopContentSkeleton}
                mobileContent={mobileContent}
              />
            ) : (
              <ResponsiveComponent
                desktopContent={desktopContent}
                mobileContent={mobileContent}
              />
            )}
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
};

export default OrdersPage;

import { useEffect, useState } from 'react';
import { TagProperties } from '@nimbus-ds/components';
import { CashIcon } from '@nimbus-ds/icons';
import TagStatus from '../TagStatus';

function getPaymentAppearance(
  payment_status: string,
): TagProperties['appearance'] {
  const paymentAppearances: Record<string, TagProperties['appearance']> = {
    paid: 'success',
    pending: 'warning',
    refunded: 'danger',
    voided: 'danger',
  };

  return paymentAppearances[payment_status] || 'warning';
}

function getPaymentLabel(payment_status: string): string {
  const paymentLabels: Record<string, string> = {
    paid: 'Pago recibido',
    pending: 'Pago en proceso',
    refunded: 'Pago devuelto',
    voided: 'Pago rechazado',
  };

  return paymentLabels[payment_status] || 'Pago pendiente';
}

interface PaymentStatusProps {
  status: string;
  gateway_name: string;
}

const PaymentStatus: React.FC<PaymentStatusProps> = ({
  status,
  gateway_name,
}: PaymentStatusProps) => {
  const [isMounted, setIsMounted] = useState(false);
  const [appearance, setAppearance] =
    useState<TagProperties['appearance']>('warning');
  const [label, setLabel] = useState<string>('Pago pendiente');

  useEffect(() => {
    setAppearance(getPaymentAppearance(status));
    setLabel(getPaymentLabel(status));

    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return (
    <>
      <TagStatus
        appearance={appearance}
        title={label}
        subtitle={gateway_name}
        icon={<CashIcon size={12} />}
      />
    </>
  );
};

export default PaymentStatus;

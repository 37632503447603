import nexo from '../nexoClient';
import { getStoreInfo } from '@tiendanube/nexo/helpers';
import { OrdersResponseType } from './Orders.types';
import { PickupType, Order as OrderType } from './Order.types';
import {
  CotizarEnvioRequestType,
  CotizarEnvioResponseType,
} from './CotizarEnvio.types';
import {
  ConsultarSucursalesRequestType,
  ConsultarSucursalesResponseType,
  SucursalType,
  Tipo as SucursalTipoType,
} from './ConsultarSucursales.types';
import http_build_query from './tools';
import {
  AddOrdenRequestType,
  AddOrdenResponseType,
  OrdenType,
  Destino,
  Postal,
} from './AddOrden.types';
import { DataType as CotizarEnvioDataType } from './CotizarEnvio.types';
import {
  PuntosDeTercerosRequestType,
  PuntosDeTercerosResponseType,
} from './PuntosDeTerceros.types';
import config from '../config';

const cache: { [key: string]: any } = {};

const storeId = (name: string) => {
  return 'store-id-' + name;
};

const getStoreInfoCache = async () => {
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === 'development') {
    return {
      id: 2713424,
    };
  }

  const cacheId = storeId('storeInfo');
  if (!cache[cacheId]) {
    const storeInfo = await getStoreInfo(nexo);
    cache[cacheId] = storeInfo;
  }
  return cache[cacheId];
};

const getBaseUri = async () => {
  const storeInfo = await getStoreInfoCache();
  return `${config.API_URL}/${storeInfo.id}/andreani`;
};

export const makeRequest = async (
  method: string,
  endpoint: string,
  params = {},
) => {
  const uri = await getBaseUri();
  let fullUri = uri + endpoint;
  let body = null;

  if (method == 'POST') {
    body = JSON.stringify(params);
  } else {
    fullUri += '?' + http_build_query(params);
  }

  const response = await fetch(fullUri, {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  try {
    if (response.headers.get('content-type') == 'application/pdf') {
      return await response.blob();
    }

    return await response.json();
  } catch (e) {
    console.log(e);
  }

  return null;
};

export const getEnvios = async (params = {}): Promise<OrdersResponseType> => {
  return makeRequest('GET', '/login', params);
};

export const cotizarEnvio = async (
  params: CotizarEnvioRequestType,
): Promise<CotizarEnvioResponseType> => {
  return makeRequest('GET', `/cotizar-envio`, params);
};

export const addOrden = async (
  params: AddOrdenRequestType,
): Promise<AddOrdenResponseType> => {
  return makeRequest('POST', `/add-orden`, params);
};

export const getOrden = async (
  numero_andreani: string,
): Promise<GetOrdenResponseType> => {
  return makeRequest('GET', `/orden/${numero_andreani}`);
};

export const getEnvio = async (
  numero_andreani: string,
): Promise<EnvioResponseType> => {
  return makeRequest('GET', `/envios/${numero_andreani}`);
};

export const getTrazas = async (
  numero_andreani: string,
): Promise<EnvioTrazasResponseType> => {
  return makeRequest('GET', `/envios/${numero_andreani}/trazas`);
};

export const consultarSucursales = async (
  params: ConsultarSucursalesRequestType,
): Promise<ConsultarSucursalesResponseType> => {
  return makeRequest('GET', `/sucursales`, params);
};

export const getPuntosDeTerceros = async (
  params: PuntosDeTercerosRequestType,
): Promise<PuntosDeTercerosResponseType> => {
  return makeRequest('GET', `/puntos-de-tercero`, params);
};

export const getSucursalDescripcion = (
  sucursal: SucursalType,
  defaultIdSucursal: number,
): string => {
  const prefix =
    sucursal.datosAdicionales.tipo == SucursalTipoType.Dealer
      ? 'Punto Andreani HOP'
      : 'Sucursal Andreani';
  const selectedByCustomer =
    sucursal.id === defaultIdSucursal ? ' (Elegida)' : '';
  return `${prefix} - ${sucursal.direccion.calle.trim()} ${sucursal.direccion.numero.trim()}, ${sucursal.direccion.localidad.trim()}${selectedByCustomer}`;
};

export const getEtiqueta = async (
  numero_andreani: string,
): Promise<EtiquetaResponseType> => {
  return makeRequest('GET', `/${numero_andreani}/etiquetas`, {});
};

export const contratos = [
  {
    name: 'Entrega a Domicilio',
    alias: 'entrega',
    value: '400005066',
    type: PickupType.ship,
  },
  {
    name: 'Entrega en Sucursal',
    alias: 'entrega_sucursal',
    value: '400005065',
    type: PickupType.pick,
  },
  {
    name: 'Urgente',
    alias: 'urgente',
    value: '400005064',
    type: PickupType.ship,
  },
  {
    name: 'Cambio',
    alias: 'cambio',
    value: '400005067',
    type: PickupType.ship,
  },
  {
    name: 'Cambio en Sucursal v2',
    alias: 'cambio_sucursal',
    value: '400020836',
    type: PickupType.pick,
  },
  {
    name: 'Retiro',
    alias: 'retiro',
    value: '400005068',
    type: PickupType.ship,
  },
  {
    name: 'Retiro en Sucursal v2',
    alias: 'retiro_sucursal',
    value: '400020837',
    type: PickupType.pick,
  },
];

export const isContratoSucursal = (value: string): boolean => {
  return contratos.find((c) => c.value == value)?.type == PickupType.pick;
};

export const codigoPostalOrigen = '1064';

export const getContratoEntregaByPickupType = (
  pickupType: PickupType,
): string => {
  let find;
  if (pickupType == PickupType.ship) {
    find = contratos.find((c) => c.alias == 'entrega');
  } else {
    // PickupType.pick
    find = contratos.find((c) => c.alias == 'entrega_sucursal');
  }

  return find ? find.value : '';
};

export const findContrato = (contrato: string): ContratoType | undefined => {
  return contratos.find((c) => c.value == contrato);
};

export const findContratoLabel = (contrato: string): string => {
  const find = findContrato(contrato);
  return find ? find.name : '';
};

export const printEtiqueta = async (numero_andreani: string) => {
  const uri = await getBaseUri();
  return `${uri}/${numero_andreani}/etiquetas`;
};

export const prepareOrden = (
  order: OrderType,
  formData: OrdenType,
  cotizacion: CotizarEnvioDataType,
): AddOrdenRequestType | false => {
  if (formData.sucursal === null) {
    return false;
  }

  const orden: AddOrdenRequestType = {
    contrato: formData.contrato,
    origen: {
      postal: {
        codigoPostal: '1064',
        calle: 'Balcarce',
        numero: '216',
        localidad: 'CABA',
        region: 'AR',
        pais: 'Argentina',
        componentesDeDireccion: [
          {
            meta: 'piso',
            contenido: '5',
          },
        ],
      },
    },
    remitente: {
      nombreCompleto: 'V@ndi2 S.A.',
      email: 'tiendaonline@c1rca.com.ar',
      documentoTipo: 'CUIT',
      documentoNumero: '307114515914',
      telefonos: [
        {
          tipo: 1,
          numero: '113332244',
        },
      ],
    },
    destinatario: [
      {
        nombreCompleto: order.customer.name,
        email: order.customer.email,
        documentoTipo: 'DNI',
        documentoNumero: order.billing_number,
        telefonos: [
          {
            tipo: 1, // Trabajo = 1, Celular =2, Casa = 3, Otros = 4
            numero: order.customer.phone,
          },
        ],
      },
    ],
    bultos: [
      {
        kilos: parseFloat(cotizacion.pesoAforado.toString()),
        largoCm: parseFloat(formData.largoCm.toString()),
        altoCm: parseFloat(formData.altoCm.toString()),
        anchoCm: parseFloat(formData.anchoCm.toString()),
        volumenCm: parseFloat(
          (formData.largoCm * formData.altoCm * formData.anchoCm).toString(),
        ),
        valorDeclaradoConImpuestos: parseFloat(
          formData.valorDeclarado.toString(),
        ),
        referencias: [
          {
            meta: 'idCliente',
            contenido: formData.idPedido,
          },
        ],
      },
    ],
    destino: null,
    productoAEntregar: '',
  };

  if (isContratoSucursal(formData.contrato)) {
    orden.destino = {
      sucursal: {
        id: formData.sucursal.id.toString(),
      },
    } as Destino;

    return orden;
  }

  orden.destino = {
    postal: {
      calle: order.shipping_address.address,
      numero: order.shipping_address.number,
      localidad: order.shipping_address.city,
      codigoPostal: order.shipping_address.zipcode,
      region: 'AR',
      pais: 'Argentina',
      componentesDeDireccion: [],
    } as Postal,
  } as Destino;

  if (order.shipping_address.floor) {
    orden.destino.postal.componentesDeDireccion.push({
      meta: 'departamento',
      contenido: order.shipping_address.floor,
    });
  }

  if (order.shipping_address.locality) {
    orden.destino.postal.componentesDeDireccion.push({
      meta: 'barrio',
      contenido: order.shipping_address.locality,
    });
  }

  if (order.shipping_address.city) {
    orden.destino.postal.componentesDeDireccion.push({
      meta: 'ciudad',
      contenido: order.shipping_address.city,
    });
  }

  return orden;
};

export type DefaultResponseType = {
  success: boolean;
  data: unknown;
  error: unknown;
};

export type EtiquetaResponseType = DefaultResponseType;

export type ContratoType = {
  name: string;
  alias: string;
  value: string;
  type: PickupType;
};

export type GetOrdenResponseType = Omit<DefaultResponseType, 'data'> & {
  data: {
    estado: string;
    tipo: string;
    sucursalDeDistribucion: {
      nomenclatura?: string;
      descripcion?: string;
      id?: string;
    };
    sucursalDeRendicion: {
      nomenclatura?: string;
      descripcion?: string;
      id?: string;
    };
    sucursalDeImposicion: {
      nomenclatura?: string;
      descripcion?: string;
      id?: string;
    };
    sucursalAbastecedora: {
      nomenclatura?: string;
      descripcion?: string;
      id?: string;
    };
    fechaCreacion: string;
    numeroDePermisionaria: string;
    descripcionServicio: string;
    bultos: Array<{
      numeroDeBulto: string;
      numeroDeEnvio: string;
      totalizador: string;
      linking: Array<{
        meta: string;
        contenido: string;
      }>;
    }>;
    agrupadorDeBultos: string;
    etiquetasPorAgrupador: string;
  };
};

export type EnvioResponseType = Omit<DefaultResponseType, 'data'> & {
  data: {
    numeroDeTracking: string;
    contrato: string;
    ciclo: string;
    estado: string;
    estadoId: number;
    fechaEstado: string;
    servicio: string;
    sucursalDeDistribucion: {
      nomenclatura: string;
      descripcion: string;
      id: string;
    };
    fechaCreacion: string;
    destino: {
      Postal: {
        localidad: string;
        region: string;
        pais: string;
        direccion: string;
        codigoPostal: string;
      };
    };
    remitente: {
      nombreYApellido: string;
    };
    destinatario: {
      nombreYApellido: string;
      tipoYNumeroDeDocumento: string;
      eMail: string;
    };
    bultos: Array<{
      kilos: number;
      valorDeclaradoConImpuestos: number;
      IdDeProducto: string;
      volumen: number;
    }>;
    idDeProducto: string;
    referencias: Array<string>;
  };
};

export type EnvioTrazasResponseType = Omit<DefaultResponseType, 'data'> & {
  data: EnvioTrazasResponseDataType;
};

export type EnvioTrazasResponseDataType = {
  eventos: Array<{
    Fecha: string;
    Estado: string;
    EstadoId: number;
    Traduccion: string;
    Sucursal: string;
    Ciclo: string;
    SucursalId?: number;
  }>;
};
